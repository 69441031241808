import React from "react";
import Layout from "./src/components/layout/Layout";
import ThemeProvider from "./src/theme/ThemeProvider";
import DataProvider from "./src/providers/DataProvider";

export const wrapPageElement = ({ element, props }) => {
  return (
    <DataProvider {...props}>
      <ThemeProvider>
        <Layout {...props}>{element}</Layout>
      </ThemeProvider>
    </DataProvider>
  );
};
