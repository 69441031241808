import { Box } from "@mui/material";
import React from "react";
import Company from "../company/Company";
import OurSoftware from "../ourSoftware/OurSoftware";
import QuickLinks from "../quickLinks/QuickLinks";
import WorkingWithUs from "../workingWithUs/WorkingWithUs";
import LogoSideComp from "./LogoSideComp";

const innerBodyStyle = {
  width: { xs: "90%", lg: "100%" },
  position: "relative",
};

const footerGridStyle = {
  margin: "0 auto",
  width: { xs: "90%", lg: "100%" },
  display: { xs: "grid" },
  gridTemplateColumns: {
    xs: "1fr 1fr",
    lg: "1.7fr 1fr 1fr 1fr 1fr",
  },
  alignItems: "start",
  justifyContent: "space-between",
  gap: "1.5rem",
};

const BrandComponent = () => {
  return (
    <Box sx={innerBodyStyle}>
      <Box
        sx={{
          width: { xs: "90%", lg: "100%" },
          m: "40px auto",
        }}
      >
        <LogoSideComp display={{ xs: "block", lg: "none" }} />
      </Box>
      <Box sx={footerGridStyle}>
        <LogoSideComp display={{ xs: "none", lg: "block" }} />

        <QuickLinks />

        <OurSoftware />

        <WorkingWithUs />

        <Company />
      </Box>
    </Box>
  );
};

export default BrandComponent;
